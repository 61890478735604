import * as React from 'react';

import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';

import { trackPage } from '~/backplane/analytics';
import HeroEnterprise from '~/components/Hero/HeroEnterprise';
import MainEnterprise from '~/components/MainEnterprise';

const headContent = defineMessages({
  title: {
    id: 'vcsAuthorizePage.title',
    defaultMessage: 'Login - CircleCI',
  },
  metaTitle: {
    id: 'vcsAuthorizePage.metaTitle',
    defaultMessage: 'Log in to access CircleCI',
  },
  metaDescription: {
    id: 'vcsAuthorizePage.metaDescriptionEnterprise',
    defaultMessage: 'Log into your CircleCI account',
  },
});

export default () => {
  // The page events weren't consistently getting to amplitude with useEffect, but they
  // seem to be getting there with useLayoutEffect. There are some slight
  // differences between them. https://reactjs.org/docs/hooks-reference.html#timing-of-effects
  // Don't ask me why yet :)
  React.useLayoutEffect(() => {
    trackPage('server-login', {});
  });
  const intl = useIntl();
  return (
    <>
      <Head>
        <title>{intl.formatMessage(headContent.title)}</title>
        <meta
          name='title'
          content={intl.formatMessage(headContent.metaTitle)}
        />
        <meta
          name='description'
          content={intl.formatMessage(headContent.metaDescription)}
        />
      </Head>
      <MainEnterprise>
        <HeroEnterprise />
      </MainEnterprise>
    </>
  );
};
